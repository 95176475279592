import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { codeToToken } from "../common/Authentication";

/**
 * Verify login from /oauth2/callback
 * @returns {JSX.Element} Component template
 */
const VerifyLogin: FC = () => {
  const [searchParams] = useSearchParams();

  // Verify code and state
  useEffect(() => {
    const code = searchParams?.get('code');
    const state = searchParams?.get('state');
    if (code && state) {
      codeToToken(code, state);
    }
  }, [searchParams]);

  return null;
}

export default VerifyLogin;