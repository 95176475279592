import { useCallback, useContext, useMemo, useState } from "react"
import Api from "../services/Api"
import { AppContext } from "../contexts/AppContext";

/**
 * Fetch client list from the API
 * @param {object} query Fetch query options
 * @param {object} options 
 * @returns {object} Owner object
 */
export default function useFetchClientList(query: any) {
  const { currentUser } = useContext(AppContext);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  /** Sort list by client name
   * @param list Array to sort
   * @return Sorted array 
   */
  const sortByClientName = useCallback((list: any) => {
    list = list.filter((d: any) => d?.status)
    list.sort((a: any, b: any) => {
      if (a?.status?.clientName < b?.status?.clientName) return -1;
      if (a?.status?.clientName > b?.status?.clientName) return 1;
      return 0;
    });
    return list;
  }, []);

  /**
   * Fetch data
   */
  const fetchData = useCallback((query: any) => {
    // Store data in cache
    setIsLoading(true);
    setData([]);

    Api.getClientList({ options: query, user: currentUser?.uniqueId }).then((list: any) => {
      if (list) {
        setData(sortByClientName(list));
      }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, [sortByClientName, currentUser]);

  // Fetch data when query has changed
  useMemo(() => {
    if (!data) {
      fetchData(query);
    }
  }, [query, fetchData, data]);


  return [data, isLoading, error]
}