import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ErrorMessageProps {
  title?: string,
  message?: string
}

const Wrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Circle = styled.div`
  margin: 80px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #493D66;
  color: #fff;
  width: 125px;
  height: 125px;
  border-radius: 125px;
  font-size: 70px;
  font-weight: bold;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
`

const Message = styled.p`
  text-align: center;
`

/**
 * Render a full screen error message
 * @returns {JSX.Element} Component template
 */
const ErrorMessage: FC<ErrorMessageProps> = ({title, message}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Circle>!</Circle>
      <Title>{title || t("error")}</Title>
      {message && (<Message>{message}</Message>)}
    </Wrapper>
  );
}

export default ErrorMessage;