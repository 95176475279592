import { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Header from '../components/Header';
import ErrorMessage from '../components/ErrorMessage';

/**
 * Render a 404 page
 * @returns {JSX.Element} Component template
 */
const NotFoundView: FC = () => {
  const { t } = useTranslation();

  // Update title
  useEffect(() => {
    document.title = `${t("error.pageNotFound")}`;
  }, [t]);

  return (
    <>
      <Header/>
      <ErrorMessage title={t("error.pageNotFound") || ""}/>
    </>
  );
}

export default NotFoundView;
