import { FC } from 'react';
import styled from 'styled-components';

interface ButtonProps {
  title: string,
  icon?: string,
  onClick?: () => void,
  status?: string,
  className?: string,
  disabled?: boolean
}

const ButtonElement = styled.button`
  background-color: #493D66;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 8px 15px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;

  &.warning {
    background-color: #f56e80;
  }

  &:active:not(:disabled) {
    opacity: .8;
  }

  &:disabled {
    opacity: .7;
  }
`

const Icon = styled.span`
  float: left;
  line-height: 15px;
  margin: 0 5px 0 -5px;
  font-size: 1.4em;
`;

/**
 * Render a button
 * @returns {JSX.Element} Component template
 */
const Button: FC<ButtonProps> = ({title, icon, onClick, status, className, disabled}) => {

  return (
    <ButtonElement className={className + " " + status} onClick={onClick} disabled={disabled}>
      {icon && <Icon className="material-icons">{icon}</Icon>}
      {title}
    </ButtonElement>
  );
}

export default Button;