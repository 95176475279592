import { useCallback, useContext, useMemo, useState } from "react"
import Api from "../services/Api"
import { AppContext } from "../contexts/AppContext";

/**
 * Fetch client statistics from the API
 * @param {string} id Client UUID
 * @param {string} duration Duration to fetch (day/week/month/year)
 * @param {number} timestamp 
 * @returns {object} Owner object
 */
export default function useFetchClientStatistics(clientId: string, duration?: "day" | "week" | "month" | "year", timestamp?: Date) {
  const { currentUser } = useContext(AppContext);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>()
  
  const getDaysInMonth = (month: number) => new Date(1999, month, 0).getDate()

  /**
  * Get the start and end date of a day, week, month or year timespan
  * @param {string} duration - Time duration (day|week|month|year)
  * @param {timestamp} timestamp - Timestamp within the timespan. Default: now
  */
 const getStatisticsTimespan = useCallback((duration: string, timestamp: Date) => {
   let startDate = new Date(timestamp), endDate = new Date();
   startDate.setHours(0,0,0,0);
   endDate.setHours(0,0,0,0);

   switch (duration) {
     case "week":
       startDate.setDate(startDate.getDate() - (startDate.getDay() + 6) % 7);
       endDate = new Date(startDate)
       endDate.setDate(startDate.getDate() + 7)
       break;
     case "month":
      startDate.setDate(1);
      endDate.setMonth(startDate.getMonth())
      endDate.setDate(getDaysInMonth(new Date(startDate).getMonth()));
       break;
     case "year":
       startDate.setDate(1);
       startDate.setMonth(0);
       startDate.setFullYear(startDate.getFullYear());
       endDate.setDate(1);
       endDate.setMonth(0);
       endDate.setFullYear(startDate.getFullYear() + 1)
       break;
     default:
       duration = "day";
       startDate.setDate(startDate.getDate());
       endDate.setDate(startDate.getDate() + 1)
       break;
   }

   return {start: startDate, end: endDate};
 }, []);

  /**
   * Fetch data
   */
  const fetchData = useCallback((clientId: string, duration?: string, timestamp?: Date) => {
    const timespan = getStatisticsTimespan(duration || "day", timestamp || new Date());

    // Store data in cache
    setIsLoading(true);
    setData({});
    Api.getStatistics({ clientId: clientId, user: currentUser?.uniqueId, startDate: timespan?.start, endDate: timespan?.end }).then((list: any) => {
      if (list) { setData(list); }
      setIsLoading(false);
    }).catch((e: any) => {
      setError(e);
      setIsLoading(false);
    });
  }, [currentUser, getStatisticsTimespan]);

  // Fetch data when query has changed
  useMemo(() => {
    fetchData(clientId, duration, timestamp);
  }, [fetchData, clientId, duration, timestamp]);


  return [data, isLoading, error]
}