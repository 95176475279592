/**
* Get human readable time difference
* @param {Date} dateFuture Timestamp.
* @param {Date} dateNow Timestamp. Optional.
* @returns {string} Time difference
*/
export const timeDiff = (dateFuture: any, dateNow?: any) => {
  if (!dateNow) { dateNow = new Date(); }
  if (typeof dateFuture === "undefined") { return ""; }
  if (dateFuture < 946681200) { dateFuture = new Date(dateNow - dateFuture) }
  dateFuture = dateFuture.setHours(dateFuture.getHours() - new Date().getTimezoneOffset() / 60);
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  // Consider as active if diff is below two minutes
  if (days === 0 && hours === 0 && minutes < 2) {
    return false;
  }

  let difference = '';
  if (days > 0) {
    difference += `${days}d `;
  }
  difference += `${hours}h ${minutes}m`; 

  return difference;
}

/**
 * Display byte size in a human readable format. Example: 1024 bytes => "1 KB"
 * @param {number} bytes - Number of bytes
 * @return {string} Human readable size
 */
export const readableBytes = (bytes: number) => {
  if (bytes === 0) return "0 Byte";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
}

/**
 * Display spent time in a human readable format. Example: 1423 seconds => "23m 43s"
 * @param {integer} seconds - Number of seconds
 * @return {string} Human readable time
 */
export const readableTime = (seconds: number) => {
  var years = Math.floor(seconds / 31536000),
    max = 3,
    current = 0,
    str = "";
  if (years && current < max) {
    str += years + "y ";
    current++;
  }
  var days = Math.floor((seconds %= 31536000) / 86400);
  if (days && current < max) {
    str += days + "d ";
    current++;
  }
  var hours = Math.floor((seconds %= 86400) / 3600);
  if (hours && current < max) {
    str += hours + "h ";
    current++;
  }
  var minutes = Math.floor((seconds %= 3600) / 60);
  if (minutes && current < max) {
    str += minutes + "m ";
    current++;
  }
  seconds = seconds % 60;
  if (seconds && current < max) {
    str += seconds + "s ";
    current++;
  }
  if (str === "") {
    str = "0s";
  }
  return str;
}
