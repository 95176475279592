import { FC, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';

interface SegmentWrapperProps {
  children: any
}

interface SegmentItemProps {
  label: string,
  icon?: string,
  isActive?: boolean,
  onClick?: () => void
}

export const SegmentWrapperElement = styled.div`
  border: 1px solid #493D66;
  border-radius: 3px;
  display: inline-flex;
  text-align: center;
  margin-bottom: 15px;

  &.desktop {
    padding: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SegmentItemElement = styled.button`
  padding: 7px 20px;
  min-width: 70px;
  -webkit-tap-highlight-color: #4A90E210;
  cursor: pointer;
  border: none;
  border: 0.5px solid #493D66;
  background: transparent;

  &.active {
    background-color: #493D66;
    color: #fff;
  }
`

const Icon = styled.span`
  float: left;
  line-height: 15px;
  margin: 0 18px 0 2px;
  width: 20px;
  display: block;
  font-size: 1.6em;
`;

/**
 * Render navigation segment wrapper
 * @returns {JSX.Element} Component template
 */
export const SegmentWrapper: FC<SegmentWrapperProps> = ({children}) => {
  const { isDesktop } = useContext(AppContext);

  return <SegmentWrapperElement role="tablist" className={isDesktop ? "desktop" : undefined}>{children}</SegmentWrapperElement>
}

/**
 * Render navigation segments
 * @returns {JSX.Element} Component template
 */
export const SegmentItem: FC<SegmentItemProps> = ({label, icon, isActive, onClick}) => {
  return (
    <SegmentItemElement role="tab" aria-selected={isActive ? "true" : "false"} className={isActive ? "active" : undefined} onClick={onClick}>
      {icon && <Icon className="material-icons">{icon}</Icon>}
      {label}
    </SegmentItemElement>
  );
}