import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import useFetchClientList from '../hooks/useFetchClientList';
import { ListItem, ListWrapper } from './List';
import { timeDiff } from '../common/Measure';
import SearchBar from './SearchBar';
import Button from './Button';
import Api from '../services/Api';
import { AppContext } from '../contexts/AppContext';
import Overlay from './Overlay';
import useGetUserPriveleges from '../hooks/useGetUserPriveleges';
import ErrorMessage from './ErrorMessage';

interface ClientPickerModalProps {
  configId?: string,
  setShowClientPicker?: any
}

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity .5s;
`;

const ModalTransition = keyframes`
  from { top: 150% }
  to { top: 50%; }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: #fff;
  overflow: auto;
  border: 2px solid #fff;
  border-radius: 5px;
  animation: ${ModalTransition} 0.5s;
`;

const HeaderElement = styled.header`
  background-color: #493D66;
  width: 100%;
  min-height: 52px;
  position: relative;
  z-index: 20;
  padding: 12px 25px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: inset 0px -0.5px 0px #CCCCCC;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 1.1em;
  font-weight: bold;
  margin: 5px 0;
  padding: 0 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ButtonWrapper = styled.div`
  margin: 10px 15px;
  float: left;
  width: calc(100% - 30px);

  &.desktop {
    margin: 0;
    width: 100%;
  }
`;

const SendButton = styled(Button)`
  width: 100%;
  margin: 0 0 5px;
`;

/**
 * Render a simple overlay with an icon and/or a message
 * @returns {JSX.Element} Component template
 */
const ClientPickerModal: FC<ClientPickerModalProps> = ({configId, setShowClientPicker}) => {
  const { t } = useTranslation();
  const { currentUser } = useContext(AppContext);
  const [clientGroups, setClientGroups] = useState<any[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [filteredClientList, setFilteredClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<string>(); 
  const [currentTime] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [clientList, fetchIsLoading] = useFetchClientList({});
  const priveleges = useGetUserPriveleges();

  // Confirm dialog
  useEffect(() => {
    if (isConfirmed) {
      setIsLoading(false);
      setTimeout(() => {
        setIsConfirmed(false);
      }, 2500);
    }
  }, [isConfirmed]);

  /**
   * Get a list of all the exhibit groups based on client list
   * @param clientList List of clients
   */
  useEffect(() => {
    if (!filteredClientList) { return }
    let clientGroupsList: any[] = [];

    // Get the client groups from client list
    filteredClientList.forEach((client: any) => {
      if (!(clientGroupsList.indexOf(client.status.exhibitGroup) > -1)) {
        clientGroupsList.push(client.status.exhibitGroup);
      }
    });

    // Sort alphabetically
    clientGroupsList.sort();

    // Move the "New clients" group to the end of the array
    let newClientsIndex = clientGroupsList.indexOf("New clients");
    if (newClientsIndex >= 0) {
      clientGroupsList.push(clientGroupsList.splice(newClientsIndex, 1)[0]);
    }

    setClientGroups(clientGroupsList);
  }, [filteredClientList]);

  // Filter list based on search phrase. Search within the client name, exhibit group, clientID and hostname
  useEffect(() => {
    const phrase = searchPhrase?.toLowerCase();
    setFilteredClientList(clientList.filter((client: any) => client?.status?.clientName?.toLowerCase().includes(phrase) || client?.status?.exhibitGroup?.toLowerCase().includes(phrase) || client?.clientId.toLowerCase().includes(phrase) || client?.status?.system?.hostname?.toLowerCase().includes(phrase)));
  }, [clientList, searchPhrase]);

  /**
   * Close modal if overlay wrapper is clicked
   */
  const closeModal = useCallback((e: any) => {
    if (e?.target?.className?.includes("modal")) {
      setShowClientPicker(false)
    }
  }, [setShowClientPicker]);

  const sendToClient = useCallback(() => {
    if (!selectedClient || !configId) { return }

    setIsLoading(true);
    Api.setupClient({sourceId: configId, destId: selectedClient, user: currentUser}).then(() => {
      setIsConfirmed(true);
      setTimeout(() => {
        setShowClientPicker(false);
      }, 2000);
    });
  }, [configId, selectedClient, currentUser, setShowClientPicker]);

  if (priveleges?.role !== "superadmin") {
    if (!priveleges) { return null }
    return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"} message={t("error.missingPrivelegesBody") || ""}/>;
  }

  return (
    <OverlayWrapper onClick={closeModal} className="modal">
      <Modal>
        {(isLoading || fetchIsLoading) && (<Overlay visibility={true} messageType="loading" position='sticky'/>)}
        {isConfirmed && (<Overlay visibility={true} messageType="check" position='sticky'/>)}
        
        <HeaderElement><Title>{t("config.send")}</Title></HeaderElement>
        <SearchBar env="mobile" setSearchPhrase={setSearchPhrase}/>
        {clientGroups?.map((groupName: any, i: number) => {
          return <ListWrapper key={`group${i}`} title={groupName}>
            {filteredClientList?.map((client: any, j: number) => {
              if (client?.status?.exhibitGroup !== groupName) { return null }

              // Get time diff
              const diffTime = timeDiff(new Date(client.updatedAt), currentTime);

              // Construct tags
              let tags = [];
              tags.push(`v${client?.status?.versions?.kulturio || "0.0.1"}`);
              if (client?.status?.interface?.status === "error") { tags.push(t('clients.error')) }
              if (client?.status?.interface?.status === "missing") { tags.push(t('clients.interfaceMissing')) }
              if (diffTime) { tags.push(diffTime); }

              // Render client
              return <ListItem key={`client${j}`} title={client?.status?.clientName} tags={tags} radio={true} checked={selectedClient === client?.status?.clientId} state={!Boolean(diffTime) ? "active" : "inactive"} onClick={() => setSelectedClient(client?.status?.clientId)}/>
            })}
          </ListWrapper>
        })}
        <ButtonWrapper>
          <SendButton title={t("config.send")} onClick={sendToClient} disabled={!Boolean(selectedClient)}/>
        </ButtonWrapper>
      </Modal>
    </OverlayWrapper>
  );
};

export default ClientPickerModal;