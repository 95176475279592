import { FC, useCallback, useContext, useEffect, useState } from "react";
import { ListItem, ListWrapper } from "../components/List";
import ErrorMessage from "../components/ErrorMessage";
import Header from "../components/Header";
import Overlay from "../components/Overlay";
import ClientStatistics from "../components/ClientStatistics";
import Api from "../services/Api";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { TabBarItem, TabBarWrapper } from "../components/Tabs";
import { Paper, PaperItem } from "../components/Paper";
import { readableBytes, readableTime } from "../common/Measure";
import useGetUserPriveleges from "../hooks/useGetUserPriveleges";
import useFetchClientDetails from "../hooks/useFetchClientDetails";

/**
 * Client details
 * @returns {JSX.Element} Component template
 */
const ClientDetails: FC = () => {
  const { t } = useTranslation();
  const { clientId } = useParams<{clientId: string}>();
  const { currentUser, bypassGroup } = useContext(AppContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientDetails, fetchIsLoading, error] = useFetchClientDetails(clientId);
  const priveleges = useGetUserPriveleges();

  // Confirm dialog
  useEffect(() => {
    if (isConfirmed) {
      setIsLoading(false);
      setTimeout(() => { setIsConfirmed(false); }, 2500);
    }
  }, [isConfirmed]);

  // Update title
  useEffect(() => {
    document.title = `${clientDetails?.status?.clientName || t("client.client")} - Kulturio Remote`;
  }, [clientDetails, t]);

  /**
   * Execute command
   */
  const sendCommand = useCallback((command: string) => {
    if (!clientId || !command) { return }

    setIsLoading(true);
    Api.sendTasksToClient({clientId: clientId, tasks: [{ "type": "execute","function": command }], user: currentUser}).then(() => {
      setIsConfirmed(true);
    });
  }, [clientId, currentUser]);

  const reinstallClient = useCallback(() => {
    const confirmation = window?.confirm(t("config.confirm") || "Are you sure?");
    if (!clientId || !confirmation) { return }

    setIsLoading(true);
    Api.setupClient({sourceId: clientId, user: currentUser}).then(() => {
      setIsConfirmed(true);
    });
  }, [clientId, currentUser, t]);

  const deleteClient = useCallback(() => {
    const confirmation = window?.confirm(t("config.confirm") || "Are you sure?");
    if (confirmation && clientId) {
      setIsLoading(true);
      Api.deleteClient({id: clientId, user: currentUser}).then(() => {
        setIsConfirmed(true);
        setTimeout(() => { navigate("/") }, 2000);
      });
    }
  }, [clientId, currentUser, navigate, t]);

  if (!["superadmin", "superuser"].includes(priveleges?.role) && (clientDetails?.status && !bypassGroup?.includes(clientDetails?.status?.exhibitGroup))) { 
    if (!priveleges) { return null }
    return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"} message={t("error.missingPrivelegesBody") || ""}/>; 
  }

  if (error) { return <ErrorMessage title={t("error.fetch") || "Fetch error"} message={t("error.fetchBody") || ""}/> }

  return (
    <>
      <Header title={clientDetails?.status?.clientName}/>
      {(isLoading || fetchIsLoading) && (<Overlay visibility={true} messageType="loading"/>)}
      {isConfirmed && (<Overlay visibility={true} messageType="check"/>)}

      {["superadmin"].includes(priveleges?.role) && (
        <TabBarWrapper>
          <TabBarItem label={t("tabs.control") || "Control"} icon={"apps"} isActive={activeTab === 0} onClick={() => setActiveTab(0)}/>
          <TabBarItem label={t("tabs.maintenance") || "Maintenance"} icon={"settings"} isActive={activeTab === 1} onClick={() => setActiveTab(1)}/>
          <TabBarItem label={t("tabs.status") || "Status"} icon={"feed"} isActive={activeTab === 2} onClick={() => setActiveTab(2)}/>
        </TabBarWrapper>
      )}
      
      {(activeTab === 0 && clientDetails?.status) && (
        <>
          {clientId && (<ClientStatistics clientId={clientId}/>)}
          {Symbol.iterator in Object(clientDetails?.status?.remoteControl) && clientDetails?.status?.remoteControl?.map((group: any, i: number) => {
            return <ListWrapper key={`group${i}`} title={group?.name}>
              {Object.keys(group?.commands).map((task: any, j: number) => {
                if (group?.commands?.hasOwnProperty(task)) {
                  const command = group?.commands[task];
                  return <ListItem key={`task${j}`} title={command?.name} icon={command?.icon} onClick={() => sendCommand(task)}/>
                } else {
                  return <></>
                }
              })}
            </ListWrapper>
          })}
          <ListWrapper title={t("client.client") || "Client"}>
            <ListItem title={t("client.restart") || "Restart application"} icon="refresh" onClick={() => sendCommand("restart")}/>
            <ListItem title={t("client.reboot") || "Rebook client"} icon="refresh" onClick={() => sendCommand("reboot")}/>
          </ListWrapper>
          <ListWrapper title={t("client.caching") || "Caching"}>
            <ListItem title={t("client.clearContent") || "Clear content cache"} icon="sync_alt" onClick={() => sendCommand("clearDocumentCache")}/>
            <ListItem title={t("client.clearMedia") || "Clear media cache"} icon="sync_alt" onClick={() => sendCommand("clearMediaCache")}/>
          </ListWrapper>
        </>
      )}

      {["superadmin"].includes(priveleges?.role) && (
        <>
          {activeTab === 1 && (
            <>
              <ListWrapper title={t("client.debug") || "Debug"}>
                <ListItem title={t("client.showClientDetails") || "Show client details"} icon="wysiwyg" onClick={() => sendCommand("revealAppInfo")}/>
                <ListItem title={t("client.showDevTools") || "Show devtools"} icon="code" onClick={() => sendCommand("revealDevTools")}/>
              </ListWrapper>
              <ListWrapper title={t("client.administration") || "Administration"}>
                <ListItem title={t("client.reinstallAll") || "Reinstall interface"} icon="download" onClick={reinstallClient}/>
                <ListItem title={t("client.remove") || "Remove"} icon="delete" onClick={deleteClient}/>
              </ListWrapper>
            </>
          )}

          {activeTab === 2 && (
            <>
              <Paper title={t("client.system")}>
                <PaperItem label={t("client.hostname")}>{clientDetails?.status?.system?.hostname}</PaperItem>
                <PaperItem label={t("client.cpu")}>{clientDetails?.status?.system?.cpu}</PaperItem>
                <PaperItem label={t("client.os")}>{clientDetails?.status?.system?.os}</PaperItem>
                <PaperItem label={t("client.ip")}>{clientDetails?.status?.system?.ip}</PaperItem>
                {typeof clientDetails?.status?.system?.serialports === "string" && (<PaperItem label={t("client.serialPorts")}>{clientDetails?.status?.system?.serialports?.replaceAll("<br/>", ", ")}</PaperItem>)}
                <PaperItem label={t("client.availableMemory")}>{`${readableBytes(clientDetails?.status?.system?.memory?.free)} ${t("client.of")} ${readableBytes(clientDetails?.status?.system?.memory?.total)}`}</PaperItem>
                {clientDetails?.status?.system?.timezone && (<PaperItem label={t("client.timezone")}>UTC{clientDetails?.status?.system?.timezone > 0 && "+"}{clientDetails?.status?.system?.timezone}</PaperItem>)}
              </Paper>
              <Paper title={t("client.timers")}>
                <PaperItem label={t("client.lastInteraction")}>{readableTime(clientDetails?.status?.upTime?.lastInteraction)}</PaperItem>
                <PaperItem label={t("client.lastRestart")}>{readableTime(clientDetails?.status?.upTime?.app)}</PaperItem>
                <PaperItem label={t("client.lastReboot")}>{readableTime(clientDetails?.status?.upTime?.os)}</PaperItem>
              </Paper>
              <Paper title={t("client.cache")}>
                <PaperItem label={t("client.documents")}>{`${Number(clientDetails?.status?.cache?.documents?.count)} (${readableBytes(clientDetails?.status?.cache?.documents?.size)})`}</PaperItem>
                <PaperItem label={t("client.mediaFiles")}>{`${Number(clientDetails?.status?.cache?.media?.count)} (${readableBytes(clientDetails?.status?.cache?.media?.size)})`}</PaperItem>
              </Paper>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ClientDetails;