import { FC, useCallback, useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { styled } from "styled-components";
import { ListItem, ListWrapper } from "../components/List";
import { v4 as uuid } from 'uuid';
import { AppContext } from "../contexts/AppContext";
import useFetchConfigDetails from "../hooks/useFetchConfigDetails";
import { useNavigate, useParams } from "react-router-dom";
import Overlay from "../components/Overlay";
import Api from "../services/Api";
import ClientPickerModal from "../components/ClientPickerModal";
import useGetUserPriveleges from "../hooks/useGetUserPriveleges";
import ErrorMessage from "../components/ErrorMessage";

const JsonEditor = styled.textarea`
  width: 100%;
  height: 300px;
  white-space: nowrap;
  border: 1px solid #aaa;
  border-radius: 5px;
`;

const ButtonWrapper = styled.div`
  margin: 10px 15px;
  float: left;
  width: calc(100% - 30px);

  &.desktop {
    margin: 0;
    width: 100%;
  }
`;

const ConfigButton = styled(Button)`
  width: 100%;
  margin: 0 0 5px;
`;

const ListTitle = styled.label`
  width: 35%;
`;

const ListInput = styled.input`
  width: 65%;
  border: none;
  padding: 10px 15px;
`;

/**
 * Edit or create new configuration
 * @returns {JSX.Element} Component template
 */
const EditConfig: FC = () => {
  const { isDesktop, currentUser } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { configId } = useParams<{configId: string}>();
  const [jsonIsValid, setJsonIsValid] = useState<boolean>(true);
  const [jsonConfig, setJsonConfig] = useState<string>("");
  const [clientKey, setClientKey] = useState<string>();
  const [clientGui, setClientGui] = useState<string>();
  const [data, fetchIsLoading, error] = useFetchConfigDetails(configId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [showClientPicker, setShowClientPicker] = useState<boolean>(false);
  const priveleges = useGetUserPriveleges();

  // Confirm dialog
  useEffect(() => {
    if (isConfirmed) {
      setIsLoading(false);
      setTimeout(() => {
        setIsConfirmed(false);
      }, 2500);
    }
  }, [isConfirmed]);

  // Get API data or defaulty data
  useEffect(() => {
    if (data?.config) {
      setClientKey(data?.config?.key);
      setClientGui(data?.repositoryUrl);
      setJsonConfig(JSON.stringify(data?.config, null, 2));  

    } else {
      const clientUuid = uuid();

      setClientKey(clientUuid);
      setClientGui("https://kiosk.kulturio.org/static/kiosk.zip");

      setJsonConfig(JSON.stringify({   
        "group": "Kulturio Kiosk",
        "title": "",
        "key": clientUuid,
        "interface": {
          "path": "kiosk/index.html",
          "deviceId": 0
        }
      }, null, 2));
    }
  }, [setJsonConfig, setClientKey, data]);

  // Update title
  useEffect(() => {
    document.title = `${data?.config?.title || "Config"} - Kulturio Remote`;
  }, [data]);

  /**
   * Update key and config when key has been changed
   */
  const changedClientKey = useCallback((e: any) => {
    const key = e?.target?.value;
    try {
      const tempConfig = JSON.parse(jsonConfig);
      tempConfig.key = key;
      setClientKey(key);
      setJsonConfig(JSON.stringify(tempConfig, null, 2))
    } catch(e) {}
  }, [jsonConfig]);

  /**
   * Update config and key when config has been changed
   */
  const changedConfig = useCallback((e: any) => {
    try {
      const config = JSON.parse(e?.target?.value);
      config?.key && setClientKey(config?.key);
      setJsonIsValid(true);
    } catch (e) {
      setJsonIsValid(false);
    }
    setJsonConfig(e.target.value)
  }, []);

  /**
   * User clicks the save button
   */
  const saveConfig = useCallback(() => {
    if (!clientKey || clientKey?.length < 10) { return; }

    const updatedConfig = {
      clientId: clientKey?.trim(),
      config: JSON.parse(jsonConfig),
      repositoryUrl: clientGui?.trim()
    };

    setIsLoading(true);
    Api.updateConfig({ id: clientKey, body: updatedConfig, user: currentUser?.uniqueId }).then(() => {
      setIsConfirmed(true);
      navigate("/config");
    });
  }, [clientGui, clientKey, jsonConfig, currentUser, navigate]);

  /**
   * User clicks the send button
   */
  const sendConfig = useCallback(() => {
    setShowClientPicker(true);
  }, []);

  /**
   * User clicks the delete button
   */
  const deleteConfig = useCallback(() => {
    const confirmation = window?.confirm(t("config.confirm") || "Are you sure?");
    if (confirmation && clientKey) {
      setIsLoading(true);
      Api.deleteConfig({ id: clientKey, user: currentUser?.uniqueId }).then(() => {
        setIsConfirmed(true);
        navigate("/config");
      });
    }
  }, [clientKey, t, navigate, currentUser]);

  if (priveleges?.role !== "superadmin") { 
    if (!priveleges) { return null }
    return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"} message={t("error.missingPrivelegesBody") || ""}/>; 
  }

  if (error) { return <ErrorMessage title={t("error.fetch") || "Fetch error"} message={t("error.fetchBody") || ""}/> }

  return (
    <>
      <Header title={configId ? t("config.editConfig") || "Edit config" : t("config.newConfig") || "New config"}/>
      {(fetchIsLoading || isLoading) && (<Overlay visibility={isLoading || fetchIsLoading} messageType="loading"/>)}
      {isConfirmed && (<Overlay visibility={isConfirmed} messageType="check"/>)}

      {showClientPicker && (<ClientPickerModal configId={clientKey} setShowClientPicker={setShowClientPicker}/>)}

      <ListWrapper title={t("config.general") || "General"}>
        <ListItem>
          <ListTitle htmlFor="key">{t("config.key") || "Key"}</ListTitle>
          <ListInput id="key" value={clientKey || "Client key"} onChange={changedClientKey}/>
        </ListItem>
        <ListItem>
          <ListTitle htmlFor="repo">{t("config.repositoryUrl")}</ListTitle>
          <ListInput id="repo" value={clientGui || ""} onChange={(e) => setClientGui(e?.target?.value)}/>
        </ListItem>
      </ListWrapper>
      <ListWrapper title={t("config.configuration") || "Configuration"}>
        <ListItem>
          <JsonEditor value={jsonConfig || "{}"} onChange={changedConfig}/>
        </ListItem>
      </ListWrapper>
      <ButtonWrapper className={isDesktop ? "desktop" : undefined}>
        <ConfigButton title={t("config.save")} disabled={!jsonIsValid} onClick={saveConfig}/>
        {configId && (
          <>
            <ConfigButton title={t("config.send")} disabled={!jsonIsValid} onClick={sendConfig}/>
            <ConfigButton title={t("config.delete")} status="warning" disabled={!jsonIsValid} onClick={deleteConfig}/>
          </>
        )}
      </ButtonWrapper>
    </>
  );
}

export default EditConfig;