import { FC, useCallback, useContext } from 'react';
import { useTranslation } from "react-i18next";
import styled, { keyframes } from 'styled-components';
import packageJson from '../../package.json'
import Menu from './Menu';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as LogoSvg } from "../assets/logo.svg";
import { ReactComponent as CloseSvg } from "../assets/icon_close.svg";

interface SidebarProps {
  id?: string,
  isOpen: boolean,
  close: () => void,
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 34;
  background-color: rgba(0, 0, 0, .5);
`;

const WrapperEnterence = keyframes`
  from { transform: translateX(100%); }
  to { transform: translateX(0%); }
`;

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 310px;
  background-color: #fff;
  color: #000;
  z-index: 35;
  animation: ${WrapperEnterence} 0.2s linear;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: -8px -1px 52px -4px rgba(0, 0, 0, .3);
`;

const LogoElement = styled(LogoSvg)`
  margin: 25px 10px 20px 20px;
  height: 50px;
  width: 50px;
  float: left;

  & path {
    fill: #000 !important;
  }
`

const AppName = styled.div`
  float: left;
  width: 190px;
  margin: 25px 25px 29px 0px;
`;

const CloseIcon = styled.button`
  cursor: pointer;
  top: 4px;
  right: 0;
  margin: 20px;
  position: absolute;
  background: transparent;
  border: none;
  padding: 0;

  &&::before {
    content: "";
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & path {
    fill: #000 !important;
  }
`;

const Separator = styled.hr`
  width: 100%;
  clear: both;
  border-top: 1px solid #ECECEC;
  border-bottom: none;
  margin: 20px 0;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 100%;
  border-top: 1px solid #ECEBEF;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  float: left;
  margin-right: 15px;
  background-color: #ECEBEF;
`;

const Initials = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  float: left;
  margin-right: 15px;
  background-color: #ECEBEF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #555;
  overflow: hidden;
`;

const UserName = styled.span`
  font-weight: bold;
  display: block;
`;

const Logout = styled.a``;

/**
 * Render the sidebar for mobile displays
 * @returns {JSX.Element} Component template
 */
const SidebarMobile: FC<SidebarProps> = ({id, isOpen, close}) => {
  const { currentUser } = useContext(AppContext);
  const { t } = useTranslation();

  const getInitials = useCallback((name: string) => {
    if (name && name?.length > 0) {
      return name.split(" ").map((n)=>n[0]);
    }
  }, []);

  return (
    <>
      {isOpen && (
        <>
          <Backdrop className={isOpen ? "open" : "closed"} onClick={close}></Backdrop>
          <Wrapper id={id}>
            <CloseIcon onClick={close} aria-label={t("presentation.close") || ""}><CloseSvg/></CloseIcon>

            <LogoElement/>
            <AppName>
              <strong>{`Kulturio Remote v${packageJson.version}`}</strong><br/>
              KulturIT AS
            </AppName>

            <Separator/>

            <Menu/>

            {currentUser && (
            <Bottom>
              {currentUser?.imageUrl ? (<Avatar src={currentUser?.imageUrl}/>) : <Initials>{getInitials(currentUser?.name)}</Initials>}
              <UserName>{currentUser?.name}</UserName>
              <Logout href="/logout">{t("menu.logout")}</Logout>
            </Bottom>
            )}
          </Wrapper>
        </>
      )}
    </>
  )
}
export default SidebarMobile;