import { useContext, useEffect, useState } from "react"
import { AppContext } from "../contexts/AppContext";
import { getPriveleges } from "../common/Authentication";

/**
 * Get user priveleges from user object
 * @returns {object} User priveleges
 */
export default function useGetUserPriveleges() {
  const { currentUser } = useContext(AppContext);
  const [userPriveleges, setUserPriveleges] = useState<any>();

  // Get user priveleges
  useEffect(() => {
    setUserPriveleges(getPriveleges(currentUser));
  }, [currentUser]);

  return userPriveleges
}