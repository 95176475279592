import { FC, useCallback, useEffect, useState } from "react";
import { ListItem, ListWrapper } from "../components/List";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchConfigList from "../hooks/useFetchConfigList";
import Overlay from "../components/Overlay";
import useGetUserPriveleges from "../hooks/useGetUserPriveleges";
import ErrorMessage from "../components/ErrorMessage";

/**
 * List clients
 * @returns {JSX.Element} Component template
 */
const ClientList: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [clientGroups, setClientGroups] = useState<any[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>(params.get('q') || "");
  const [filteredClientList, setFilteredClientList] = useState<any>([]);
  const [clientList, isLoading, error] = useFetchConfigList({rows: 1000});
  const priveleges = useGetUserPriveleges();

  /**
   * Get a list of all the exhibit groups based on client list
   * @param clientList List of clients
   */
  useEffect(() => {
    if (!filteredClientList) { return }
    let clientGroupsList: any[] = [];

    // Get the client groups from client list
    filteredClientList.forEach((client: any) => {
      if (!(clientGroupsList.indexOf(client.config.group) > -1)) {
        clientGroupsList.push(client.config.group);
      }
    });

    // Sort alphabetically
    clientGroupsList.sort();

    setClientGroups(clientGroupsList);
  }, [filteredClientList]);

  /**
   * Navigate to add page
   */
  const addClient = useCallback(() => {
    navigate("new");
  }, [navigate]);


  // Update URL when search is performed
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchPhrase.length > 0) {
        navigate(`/config?q=${searchPhrase}`);
      } else {
        navigate(`/config`);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchPhrase, navigate]);

  // Filter list based on search phrase. Search within the client name, exhibit group, clientID and hostname
  useEffect(() => {
    const phrase = searchPhrase?.toLowerCase();
    setFilteredClientList(clientList?.filter((client: any) => client?.config?.group?.toLowerCase().includes(phrase) || client?.config?.title?.toLowerCase().includes(phrase) || client?.config?.key?.toLowerCase().includes(phrase)));
  }, [clientList, searchPhrase]);

  // Update title
  useEffect(() => {
    document.title = `${t("menu.configTool")} - Kulturio Remote`;
  }, [t]);

  if (priveleges?.role !== "superadmin") { 
    if (!priveleges) { return null }
    return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"} message={t("error.missingPrivelegesBody") || ""}/>;
  }

  if (error) { return <ErrorMessage title={t("error.fetch") || "Fetch error"} message={t("error.fetchBody") || ""}/> }

  return (
    <>
      <Header title={t("config.configurations") || "Configurations"} showSearchBar={true} searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase} showAddButton={true} addButtonAction={addClient}/>
      {isLoading && (<Overlay visibility={isLoading} messageType="loading"/>)}

      {clientGroups?.map((groupName: any, i: number) => {
        return <ListWrapper key={`group${i}`} title={groupName}>
          {filteredClientList?.map((client: any, j: number) => {
            if (client?.config?.group !== groupName) { return null }

            // Construct tags
            let tags = [];
            tags.push(`v${client?.config?.key}`);

            // Render client
            return <ListItem key={`client${j}`} title={client?.config?.title} showChevron={true} tags={tags} icon="code" onClick={() => navigate(client?.config?.key)}/>
          })}
        </ListWrapper>
      })}
    </>
  );
}

export default ClientList;