import { FC, useCallback, useContext } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import Menu from './Menu';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as LogoSvg } from "../assets/logo_white.svg";

interface SidebarProps {}

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 290px;
  background-color: #493D66;
  color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
`;

const LogoElement = styled(LogoSvg)`
  margin: 35px auto 25px;
  height: 60px;
  width: 150px;
  display: block;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  padding: 25px;
  width: 100%;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  float: left;
  margin-right: 15px;
  background-color: #ECEBEF;
`;

const Initials = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  float: left;
  margin-right: 15px;
  background-color: #ECEBEF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #555;
  overflow: hidden;
`;

const UserName = styled.span`
  font-weight: bold;
  display: block;
`;

const Logout = styled.a`
  color: #fff;
`;

/**
 * Render the sidebar for mobile displays
 * @returns {JSX.Element} Component template
 */
const SidebarDesktop: FC<SidebarProps> = () => {
  const { currentUser } = useContext(AppContext);
  const { t } = useTranslation();

  const getInitials = useCallback((name: string) => {
    return name?.split(" ").map((n)=>n[0]);
  }, []);

  return (
    <Wrapper>
      <LogoElement/>

      <Menu/>

      {currentUser && (
        <Bottom>
          {currentUser?.imageUrl ? (<Avatar src={currentUser?.imageUrl}/>) : <Initials>{getInitials(currentUser?.name)}</Initials>}
          <UserName>{currentUser?.name}</UserName>
          <Logout href="/logout">{t("menu.logout")}</Logout>
        </Bottom>
      )}
    </Wrapper>
  )
}
export default SidebarDesktop;