import { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SearchIcon from "../assets/input_search.svg";
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  title?: string,
  env: "desktop" | "mobile",
  searchPhrase?: string,
  setSearchPhrase?: any
}

const SearchBarElement = styled.input`
  padding: 8px 20px;
  width: calc(100% - 30px);
  border: none;
  border-radius: 6px;
  margin: 15px 15px;
  font-size: 16px;
  background: #F4F4F4 url(${SearchIcon}) calc(100% - 15px) 50% no-repeat;
  -webkit-user-select: text;

  @media (min-width: 1000px) {
    margin: 5px 15px;
  }

  &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration { display: none; }
  &::-ms-clear, &::-ms-reveal { display: none; width : 0; height: 0; }

  &.desktop {
    float: right;
    width: 20%;
    margin-right: 0;
    transition: width .2s;

    &:focus {
      width: 30%;
    }
  }
`

/**
 * Render the search bar
 * @returns {JSX.Element} Component template
 */
const SearchBar: FC<SearchBarProps> = ({title, env, searchPhrase, setSearchPhrase}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchPhrase && searchRef.current) {
      searchRef.current.value = searchPhrase;
    }
  }, [searchPhrase]);

  const onChange = useCallback((e: any) => {
    setSearchPhrase && setSearchPhrase(e?.target?.value)
  }, [setSearchPhrase]);

  return (
    <SearchBarElement ref={searchRef} className={env} placeholder={`${t("search.placeholder")}..`} spellCheck="false" onChange={onChange}/>
  );
}

export default SearchBar;