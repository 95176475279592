import { FC, useContext, useEffect, useState } from "react";
import { ListItem, ListWrapper } from "../components/List";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchClientList from "../hooks/useFetchClientList";
import { timeDiff } from "../common/Measure";
import Overlay from "../components/Overlay";
import useGetUserPriveleges from "../hooks/useGetUserPriveleges";
import ErrorMessage from "../components/ErrorMessage";
import { AppContext } from "../contexts/AppContext";

/**
 * List clients
 * @returns {JSX.Element} Component template
 */
const ClientList: FC = () => {
  const { t } = useTranslation();
  const { bypassGroup } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [clientGroups, setClientGroups] = useState<any[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>(params.get('q') || "");
  const [filteredClientList, setFilteredClientList] = useState<any>([]);
  const [currentTime] = useState<any>(new Date());
  const [clientList, isLoading, error] = useFetchClientList({});
  const priveleges = useGetUserPriveleges();

  /**
   * Get a list of all the exhibit groups based on client list
   * @param clientList List of clients
   */
  useEffect(() => {
    if (!filteredClientList) { return }
    let clientGroupsList: any[] = [];

    // Get the client groups from client list
    filteredClientList.forEach((client: any) => {
      if (!(clientGroupsList.indexOf(client.status.exhibitGroup) > -1)) {
        clientGroupsList.push(client.status.exhibitGroup);
      }
    });

    // Sort alphabetically
    clientGroupsList.sort();

    // Move the "New clients" group to the end of the array
    let newClientsIndex = clientGroupsList.indexOf("New clients");
    if (newClientsIndex >= 0) {
      clientGroupsList.push(clientGroupsList.splice(newClientsIndex, 1)[0]);
    }

    setClientGroups(clientGroupsList);
  }, [filteredClientList]);

  // Filter list based on search phrase. Search within the client name, exhibit group, clientID and hostname
  useEffect(() => {
    const phrase = searchPhrase?.toLowerCase();

    let filteredList = [];
    if (["superadmin", "superuser"].includes(priveleges?.role)) {
      filteredList = clientList;
    } else if (bypassGroup) {
      filteredList = clientList?.filter((client:any) => bypassGroup.includes(client?.status?.exhibitGroup));
    }
    
    setFilteredClientList(filteredList.filter((client: any) => client?.status?.clientName?.toLowerCase().includes(phrase) || client?.status?.exhibitGroup?.toLowerCase().includes(phrase) || client?.clientId.toLowerCase().includes(phrase) || client?.status?.system?.hostname?.toLowerCase().includes(phrase)));
  }, [clientList, searchPhrase, bypassGroup, priveleges]);

  // Update URL when search is performed
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchPhrase.length > 0) {
        navigate(`/?q=${searchPhrase}`);
      } else {
        navigate(`/`);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchPhrase, navigate]);

  // Update title
  useEffect(() => {
    document.title = `${t("menu.activeClients")} - Kulturio Remote`;
  }, [t]);

  if (!["superadmin", "superuser"].includes(priveleges?.role) && bypassGroup?.length === 0) {
    if (!priveleges) { return null }
    return <ErrorMessage title={t("error.missingPriveleges") || "Missing priveleges"} message={t("error.missingPrivelegesBody") || ""}/>; }

  if (error) { return <ErrorMessage title={t("error.fetch") || "Fetch error"} message={t("error.fetchBody") || ""}/> }

  return (
    <>
      <Header title={t("menu.activeClients") || ""} showSearchBar={true} setSearchPhrase={setSearchPhrase} searchPhrase={searchPhrase}/>
      {isLoading && (<Overlay visibility={isLoading} messageType="loading"/>)}

      {clientGroups?.map((groupName: any, i: number) => {
        return <ListWrapper key={`group${i}`} title={groupName}>
          {filteredClientList?.map((client: any, j: number) => {
            if (client?.status?.exhibitGroup !== groupName) { return null }

            // Get time diff
            const diffTime = timeDiff(new Date(client.updatedAt), currentTime);

            // Construct tags
            let tags = [];
            tags.push(`v${client?.status?.versions?.kulturio || "0.0.1"}`);
            if (client?.status?.interface?.status === "error") { tags.push(t('clients.error')) }
            if (client?.status?.interface?.status === "missing") { tags.push(t('clients.interfaceMissing')) }
            if (diffTime) { tags.push(diffTime); }

            // Render client
            return <ListItem key={`client${j}`} title={client?.status?.clientName} showChevron={true} tags={tags} state={!Boolean(diffTime) ? "active" : "inactive"} onClick={() => navigate(`/clients/${client?.status?.clientId}`)}/>
          })}
        </ListWrapper>
      })}
    </>
  );
}

export default ClientList;