import { FC, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';

interface TabBarWrapperProps {
  children: any
}

interface TabBarItemProps {
  label: string,
  icon?: string,
  hits?: number,
  isActive?: boolean,
  onClick?: () => void
}

export const TabBarWrapperElement = styled.div`
  margin: 25px 0 30px 0;
  padding: 0 15px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  width: 100%;
  position: relative;
  font-size: .9em;

  &.desktop {
    padding: 0;
    margin: 10px 0 25px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:before {
    content: "";
    border-bottom: 1px solid #493D66;
    width: 100%;
    position: absolute;
    height: calc(100% - 1px);
    z-index: 0;
  }
`;

const TabBarItemElement = styled.button`
  float: left;
  padding: 12px 18px 10px 10px;
  background-color: transparent;
  color: #493D66;
  cursor: pointer;
  font-weight: bold;
  font-size: 1em;
  flex: 0 0 auto;
  -webkit-tap-highlight-color: #4A90E210;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px 5px 0 0;
  border-color: #493D66;
  background-color: #ECEBEF;
  border: 1px solid #493D66;
  z-index: 0;
  position: relative;
  margin-left: 10px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &.active {
    background-color: #fff;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
  }
`

const Icon = styled.span`
  float: left;
  line-height: 15px;
  margin: 0 12px 0 2px;
  width: 20px;
  display: block;
  font-size: 1.6em;
`;

const HitCounter = styled.span`
  font-weight: normal;
  margin-left: 5px;
`;

/**
 * Render navigation tab wrapper
 * @returns {JSX.Element} Component template
 */
export const TabBarWrapper: FC<TabBarWrapperProps> = ({children}) => {
  const { isDesktop } = useContext(AppContext);

  return <TabBarWrapperElement role="tablist" className={isDesktop ? "desktop" : undefined}>{children}</TabBarWrapperElement>
}

/**
 * Render navigation tabs
 * @returns {JSX.Element} Component template
 */
export const TabBarItem: FC<TabBarItemProps> = ({label, icon, hits, isActive, onClick}) => {
  return (
    <TabBarItemElement role="tab" aria-selected={isActive ? "true" : "false"} className={isActive ? "active" : undefined} onClick={onClick}>
      {icon && <Icon className="material-icons">{icon}</Icon>}
      {label}
      {typeof hits !== "undefined" && <HitCounter>({hits})</HitCounter>}
    </TabBarItemElement>
  );
}