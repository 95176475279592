import { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerIcon } from '../assets/icon_spinner.svg';
import { ReactComponent as WarningIcon } from '../assets/icon_warning.svg';
import { ReactComponent as InfoIcon } from '../assets/icon_info.svg';
import { ReactComponent as CheckIcon } from '../assets/icon_check.svg';

interface OverlayProps {
  position?: "fixed" | "sticky",
  message?: string,
  messageType?: string,
  visibility: boolean
}

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, .8);
  transition: opacity .5s;

  &.sticky {
    position: sticky;
  }
`;

const SpinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Icon = styled.div`
  text-align: center;
  padding: 20px;

  &.spin svg {
    animation: ${SpinAnimation} 2s infinite linear;
  }
`;

const Message = styled.span`;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  width: 80%;
`;

/**
 * Render a simple overlay with an icon and/or a message
 * @returns {JSX.Element} Component template
 */
const Overlay: FC<OverlayProps> = ({position, message, messageType, visibility}) => {
  if (!visibility) { return null; }

  return (
    <OverlayWrapper role="alert" className={position || undefined}>
      <Message>
        <Icon className={messageType === "loading" ? "spin" : "undefined"}>
          {messageType === "loading" && (<SpinnerIcon/>)}
          {messageType === "warning" && (<WarningIcon/>)}
          {messageType === "info" && (<InfoIcon/>)}
          {messageType === "check" && (<CheckIcon/>)}
        </Icon>
        {message}
      </Message>
    </OverlayWrapper>
  );
};

export default Overlay;