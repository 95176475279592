import { createContext } from "react";

interface AppProviderProps {
  currentUser: any,
  currentLanguage: string,
  bypassGroup: string[],
  bypassKey: any,
  setCurrentUser: any,
  setCurrentLanguage: any,
  isDesktop: boolean
}

export const AppContext = createContext<Partial<AppProviderProps>>({});