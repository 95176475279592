import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import useFetchClientStatistics from "../hooks/useFetchClientStatistics";
import { styled } from "styled-components";
import ChevronLeftSvg from "../assets/chevron_left.svg";
import ChevronRightSvg from "../assets/chevron_right.svg";
import { SegmentItem, SegmentWrapper } from "./Segment";
import { useTranslation } from "react-i18next";
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface ClientStatisticsProps {
  clientId: string
}

const Wrapper = styled.div`
  float: left;
  width: 100%;
  background: #fff;
  margin: 0 0 20px;
  padding: 0 15px;
  border-radius: 2px;
  text-align: center;
  transition: opacity .5s;

  &.loading canvas {
    opacity: .5;
  }
`;

const Control = styled.div`
  display: flex;
  margin: 10px 0 0;
`;

const PrevButton = styled.button`
  float: left;
  background-image: url(${ChevronLeftSvg});
  border: 0;
  background-size: 8px;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
`;

const NextButton = styled.button`
  float: right;
  background-image: url(${ChevronRightSvg});
  border: 0;
  background-size: 8px;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
`;

const Caption = styled.span`
  margin: 0 auto;
  font-weight: bold;
`;

const ChartBar = styled(Bar)`
  max-height: 200px;
`;

ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

/**
 * Render the client statistics component
 * @returns {JSX.Element} Component template
 */
const ClientStatistics: FC<ClientStatisticsProps> = ({clientId}) => {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [duration, setDuration] = useState<"day"|"week"|"month"|"year">("day");
  const [chartCaption, setChartCaption] = useState<string>("");
  const [chartData, setChartData] = useState<any>({labels: [], datasets: []});
  const [chartOptions, setChartOptions] = useState<any>({});
  const [apiData, isLoading] = useFetchClientStatistics(clientId, duration, startTime);

  const getDaysInMonth = (month: number) => new Date(1999, month, 0).getDate()
  const zeroPadding = (digit: number) => ('0' + digit).slice(-2);

  const showPreviousPeriod = useCallback(() => {
    let newTime: any = new Date(startTime);

    switch(duration) {
      case "week": newTime = newTime.setDate(newTime.getDate() - 7); break;
      case "month": newTime = newTime.setMonth(newTime.getMonth() - 1); break;
      case "year": newTime = newTime.setFullYear(newTime.getFullYear() - 1); break;
      default: newTime = newTime.setDate(newTime.getDate() - 1); break;
    }
    setStartTime(newTime);
  }, [duration, startTime]);

  const showNextPeriod = useCallback(() => {
    let newTime: any = new Date(startTime);

    switch(duration) {
      case "week": newTime = newTime.setDate(newTime.getDate() + 7); break;
      case "month": newTime = newTime.setMonth(newTime.getMonth() + 1); break;
      case "year": newTime = newTime.setFullYear(newTime.getFullYear() + 1); break;
      default: newTime = newTime.setDate(newTime.getDate() + 1); break;
    }
    setStartTime(newTime);
  }, [duration, startTime]);

  // Construct chart.js options
  useMemo(() => {
    setChartOptions({
      responsive: true,
      layout: {
        padding: { left: 0, right: 0, top: 20, bottom: 0 }
      },
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltips: {
        enabled: false,
        intersect: true
      },
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          align: 'end',
          anchor: 'end',
          display: (context: any) => {
            return context.dataset.data[context.dataIndex] !== 0;
          }
        }
      },
      scales: {
        x: {
          grid: { display: false },
          ticks: { autoSkip: false, maxRotation: 0, minRotation: 0 },
          afterTickToLabelConversion: (data: any) => {
            var xLabels = data.ticks;
            if (xLabels.length > 12) {
              xLabels.forEach((labels: any, i: number) => {
                if (i % 2 === 1) { xLabels[i].label = ""; }
              });
            }
          }
        },
        y: {
          grid: { display: false }
        }
      },
      animation: { duration: 500, easing: "easeOutQuart" }
    });
  }, []);

  // Build chart data
  useEffect(() => {
    if (!Array.isArray(apiData)) { return }
    let statisticsTime: any[any] = [], label = "";

    switch(duration) {
      case "week":
        const weekdays = ["", "Ma","Ti","On","To","Fr","Lø","Sø"];
        for (let i = 1; i <= 7; i++) {
          statisticsTime[weekdays[i]] = 0;
        }
        apiData.forEach((statistic: any) => {
          statisticsTime[weekdays[new Date(statistic?.timestamp).getDay()]] += statistic.pageVisits;
        });
        setChartCaption(`${zeroPadding(new Date(startTime).getDate())}.${zeroPadding(new Date(startTime).getMonth() + 1)}.${new Date(startTime).getFullYear()}`);
        break;      
      case "month":
        for (let i = 1; i <= getDaysInMonth(new Date(startTime).getMonth()); i++) {
          statisticsTime[i] = 0;
        }
        apiData.forEach((statistic: any) => {
          statisticsTime[new Date(statistic?.timestamp).getDate()] += statistic.pageVisits;
        });
        setChartCaption(`${zeroPadding(new Date(startTime).getMonth() + 1)}.${new Date(startTime).getFullYear()}`);
        break;

      case "year":
        for (let i = 1; i <= 12; i++) {
          statisticsTime[zeroPadding(i) + " "] = 0;
        }
        apiData.forEach((statistic: any) => {
          statisticsTime[zeroPadding(new Date(statistic?.timestamp).getMonth() + 1) + " "] += statistic.pageVisits;
        });
        console.log(statisticsTime)
        setChartCaption(`${new Date(startTime).getFullYear()}`);

        break;
      default:
        for (let i = 0; i < 24; i++) { statisticsTime[i] = 0; }
        apiData?.forEach((statistic: any) => {
          statisticsTime[new Date(statistic?.timestamp).getHours()] += statistic.pageVisits;
        });
        setChartCaption(new Date(startTime).toISOString().split('T')[0]);
        break;
    }

    setChartData({
      labels: Object.keys(statisticsTime),
      datasets: [
        {
          label: label,
          backgroundColor: "#493d66",
          borderColor: "#493d66",
          data: Object.values(statisticsTime)
        }
      ]
    })
  }, [apiData, startTime, duration]);

  return (
    <Wrapper className={isLoading ? "loading" : undefined}>
      <SegmentWrapper>
        <SegmentItem label={t("stats.day")} onClick={() => setDuration("day")} isActive={duration === "day"}/>
        <SegmentItem label={t("stats.week")} onClick={() => setDuration("week")} isActive={duration === "week"}/>
        <SegmentItem label={t("stats.month")} onClick={() => setDuration("month")} isActive={duration === "month"}/>
        <SegmentItem label={t("stats.year")} onClick={() => setDuration("year")} isActive={duration === "year"}/>
      </SegmentWrapper>
      <ChartBar data={chartData} options={chartOptions} width={100} height={40} />
      <Control>
        <PrevButton onClick={showPreviousPeriod}/>
        <Caption>{chartCaption}</Caption>
        <NextButton onClick={showNextPeriod}/>
      </Control>
    </Wrapper>);
}

export default ClientStatistics