import { setCookie, getCookie } from './common/Cookies';

// Kulturio backend
let pcsApiUrl = process.env.REACT_APP_PCS_API_URL || "https://pcs.kulturio.org/";
let eKulturLoginHost = process.env.REACT_APP_LOGIN_HOST || "https://login.ekultur.org";
let eKulturApiGateway = process.env.REACT_APP_LOGIN_GATEWAY || "https://apigateway.kulturio.org";
let eKulturSameSite = process.env.REACT_APP_LOGIN_SAMESITE || true;
let sendAuthTokenOnRequests = false;

// ---- Testing purposes start ----

const urlParams = new URLSearchParams(window.location.search);
const env = urlParams.get('env');
if (env) { setCookie("env", env); }

// Schema IDs - dev-KP
if (getCookie("env") === "dev") {
  pcsApiUrl = "https://dev-pcs.kulturio.org/";
  eKulturLoginHost = "https://beta-login.ekultur.org";
  eKulturApiGateway = "https://beta-apigateway.kulturio.org";
  eKulturSameSite = false;
  sendAuthTokenOnRequests = true;
}

// Schema IDs - production
if (getCookie("env") === "prod") {
  pcsApiUrl = "https://pcs.kulturio.org/";
  eKulturLoginHost = "https://login.ekultur.org";
  eKulturApiGateway = "https://apigateway.kulturio.org";
  eKulturSameSite = true;
  sendAuthTokenOnRequests = false;
}

export const EKULTUR_APP_ID = "3b1fb453-6841-4792-aa34-c08dfde7643c";
export const EKULTUR_API_GATEWAY_URL = eKulturApiGateway;
export const EKULTUR_LOGIN_HOST_URL = eKulturLoginHost;
export const KULTUR_API_SAMESITE = eKulturSameSite;
export const PCS_API_URL = pcsApiUrl;
export const PCS_API_KEY = "87e1eb21-e1ee-437c-bee0-8f7c7c3441b6";

export const SEND_AUTH_TOKEN_ON_REQUESTS: boolean = sendAuthTokenOnRequests;

// Bypass authentication
export const BYPASS_CONFIG: any[string] = {
  "sj42Zk948da": ["Impulser"],
  "td31Xi813vu": ["Tidsrom", "Museumsloftet", "Reformasjonen"],
  "oh19xCcw4qw": ["Oppdag havet", "Båthallen", "Linge i 100", "Sally Jones"],
  "9gauzt5vk4s": ["Polar Bear"]
}

// Languages, ISO 639-1
export const LANGUAGES = [
  { key: "no", name: "Norsk"},
  { key: "sv", name: "Svenska"},
  { key: "en", name: "English"}
];