import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { BYPASS_CONFIG } from "../Settings";
import { deleteCookie, getCookie, setCookie } from "../common/Cookies";

/**
 * Check if authentication should be bypassed
 * @returns {object} 
 */
export default function useCheckBypassAuthentication() {
  const { search } = useLocation();
  const [bypassKey, setBypassKey] = useState<string>();
  const [bypassGroup, setBypassGroup] = useState<string[]>([]);

  // Get auth key from path or querystring
  useEffect(() => {
    // Get key from path
    let pathKey = null;
    const currentPath = window.location.pathname;
    if (currentPath && currentPath?.includes("/key/")) {
      pathKey = currentPath.replace("/key/", "");
    }

    // Get key from querystring
    const params = new URLSearchParams(search);
    const queryKey = params.get('key');

    // Get key from cookie
    const cookieKey = getCookie("remoteGroup");

    if (pathKey) {
      setBypassKey(pathKey);
    } else if (queryKey) {
      setBypassKey(queryKey);
    } else if (cookieKey) {
      setBypassKey(cookieKey);
    }
  }, [search]);

  // Auth key is set
  useEffect(() => {
    if (bypassKey && typeof bypassKey === "string") {
      const groups = BYPASS_CONFIG?.[bypassKey];
      if (groups?.length > 0) {
        setCookie("remoteGroup", bypassKey);
        setBypassGroup(groups);
      } else {
        deleteCookie("remoteGroup");
        console.log("BB")
        window.location.href = "/";
      }
    }
  }, [bypassKey]);


  return [bypassGroup]
}