import { FC, useEffect } from "react";
import { logout } from "../common/Authentication";

/**
 * Perform logout. Redirects to login page when finished
 * @returns {JSX.Element} Component template
 */
const Logout: FC = () => {
  useEffect(() => {
    logout();
  }, []);

  return null;
}

export default Logout;