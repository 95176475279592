import { FC, useCallback, useContext } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { ReactComponent as ClientSvg } from "../assets/icon_client.svg";
import { ReactComponent as ConfigSvg } from "../assets/icon_config.svg";
import { ReactComponent as LanguageSvg } from "../assets/icon_language.svg";
import ChevronDownSvg from "../assets/chevron_down.svg";
import ChevronUpSvg from "../assets/chevron_up.svg";
import ChevronDownWhiteSvg from "../assets/chevron_down_white.svg";
import ChevronUpWhiteSvg from "../assets/chevron_up_white.svg";
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import useGetUserPriveleges from '../hooks/useGetUserPriveleges';
import { LANGUAGES } from '../Settings';

interface MenuProps {}

const MenuItems = styled.ul`
  margin: 20px;
  padding: 0;
  list-style: none;

  &.desktop {
    li.active {
      background-color: #6BA7FF;
      border-radius: 5px;
    }

    a {
      color: #fff;
    }

    path {
      fill: #fff;
    }
  }
`;

const MenuItem = styled.li`
  padding: 0;
  margin: 0 0 8px 0;

  &.sub {
    background-position: calc(100% - 15px) 7px;
    background-repeat: no-repeat;
    background-image: url(${ChevronDownSvg});
  }

  &.desktop.sub {
    background-image: url(${ChevronDownWhiteSvg});
  }

  &.sub.expanded {
    background-image: url(${ChevronUpSvg});
  }

  &.desktop.sub.expanded {
    background-image: url(${ChevronUpWhiteSvg});
  }
`;

const MenuLink = styled(Link)`
  display: block;
  padding: 10px 20px;
  color: #000;
  text-decoration: none;
  background-position: 0 50%;
  background-repeat: no-repeat;

  &:active {
    color: #493D66;
  }

  &.active {
    font-weight: bold;
  }
`

const SubmenuItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;

  &.active {
    display: block;
  }

  & a {
    padding: 10px 20px 0px 50px;
  }
`;

const ClientIcon = styled(ClientSvg)`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
`;

const ConfigIcon = styled(ConfigSvg)`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
`

const LanguageIcon = styled(LanguageSvg)`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
`

/**
 * Render the menu
 * @returns {JSX.Element} Component template
 */
const Menu: FC<MenuProps> = () => {
  const { currentLanguage, setCurrentLanguage, isDesktop, bypassGroup } = useContext(AppContext);
  const { t } = useTranslation();
  const currentPage = useLocation()?.pathname;
  const priveleges = useGetUserPriveleges();

  /**
   * Toggle sub menu on click
   * @param e Event
   */
  const toggleSubmenu = useCallback((e: any) => {
    const submenuElement = e?.target?.parentElement?.querySelector("ul");
    if (submenuElement) {
      if (submenuElement?.classList.contains("active")) {
        submenuElement.classList.remove("active");
        e?.target.parentElement?.classList?.remove("expanded");
      } else {
        submenuElement.classList.add("active");
        e?.target.parentElement?.classList?.add("expanded");
      }
    }
     
    return false;
  }, []);

  if ((!priveleges || priveleges?.role === "none") && bypassGroup?.length === 0) { return null; }

  return (
    <>
      <MenuItems className={isDesktop ? "desktop" : undefined} role="menubar">
        <>
          <MenuItem className={(currentPage === "/" || currentPage.startsWith("/clients")) ? "active" : undefined}><MenuLink to="/"><ClientIcon/>{t("menu.activeClients") || ""}</MenuLink></MenuItem>

          {(["superadmin"].includes(priveleges?.role)) && (
            <MenuItem className={currentPage.startsWith("/config") ? "active" : undefined}><MenuLink to="/config"><ConfigIcon/>{t("menu.configTool") || ""}</MenuLink></MenuItem>
          )}
        </>
      </MenuItems>
      <MenuItems className={isDesktop ? "desktop" : undefined} role="menubar">
        <>
          <MenuItem className={isDesktop ? "sub desktop" : "sub"}>
            <MenuLink to="#" onClick={toggleSubmenu}><LanguageIcon/>{t("menu.languages") || "Languages"}</MenuLink>
            <SubmenuItems id="languagemenu" role="group">
              {LANGUAGES?.map((language, i) => {
                return <MenuItem key={`lang${i}`}><MenuLink to="#" className={(language?.key === currentLanguage) ? "active" : undefined} onClick={() => setCurrentLanguage(language?.key)}>{language?.name}</MenuLink></MenuItem>
              })}
          </SubmenuItems>
          </MenuItem>
        </>
      </MenuItems>
    </>
  )
}
export default Menu;