// @ts-ignore
import * as eKultur from '@ekultur/authentication';
import { EKULTUR_API_GATEWAY_URL, EKULTUR_APP_ID, EKULTUR_LOGIN_HOST_URL, KULTUR_API_SAMESITE } from '../Settings';

/**
 * Refresh token
 */
export const refreshToken = async () => {
  return new Promise((onSuccess, onError) => {
    eKultur.refreshToken({
      apiGateway: EKULTUR_API_GATEWAY_URL,
      onSuccess: async () => { onSuccess(await getUserId()); },
      onError: (error: any) => { console.log("Could not refresh token", error); login(); }
    });
  });
}

export const login = () => {
  eKultur.login({
    locationPostLogin: null,
    redirectUri: null,
    loginHost: EKULTUR_LOGIN_HOST_URL
  });
}

export const codeToToken = (code: string, state: string) => {
  eKultur.codeToToken({
    code,
    state,
    redirectUri: null,
    apiGateway: EKULTUR_API_GATEWAY_URL,
    sameSite: KULTUR_API_SAMESITE,
    onSuccess: ({previousPath}: any) => { window.location.href = previousPath },
    onError: (error: any) => { console.log("Verify error", error); },
  });
}

export const logout = () => {
  eKultur.logout({
    apiGateway: EKULTUR_API_GATEWAY_URL
  });

  localStorage.clear();
}

/**
 * Authentication: Get user ID. Add it to localstorage
 */
export const getUserId = async () => {
  let token = localStorage.getItem("kit.access_token");
  if(!token) { return }
  return await fetch(EKULTUR_API_GATEWAY_URL + "/authz/authorize/userData", { method: "GET", headers: { "Authorization": token || "" } }
  ).then((data) => data.json()
  ).then(data => {
    return data;
  });
}

/**
 * Authentication: Get priveleges based on user object
 * @param {object} currentUser User object
 * @returns {object} Role and list of owners
 */
export const getPriveleges = (currentUser: any) => {
  if (!currentUser) { return null }

  const globalPrivilege = currentUser?.appAccess?.apps?.filter((app: any) => app.id === EKULTUR_APP_ID)?.[0]?.role?.name;
    
  // Is super admin?
  if (globalPrivilege === "Admin") {
    return { role: "superadmin", owners: "all" };
  }

  // Is global editor or user?
  if(globalPrivilege === "Editor" || globalPrivilege === "User") {
    return { role: "superuser", owners: "all" };
  }

  // Is museum user?
  let museumIds: number[] = [];
  currentUser?.appAccess?.museums?.forEach((museum: any) => {
    const role = museum.applications?.filter((app: any) => app.id === EKULTUR_APP_ID)[0]?.role?.name;
    if (role) { museumIds.push(museum?.id) }
  });
  if (museumIds?.length > 0) { return { role: "user", owners: museumIds } }

  // No rights?
  return { role: "none", owners: null }
}