import { FC, useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from "../assets/icon_back.svg";
import { ReactComponent as MenuIcon } from "../assets/icon_menu.svg";
import { ReactComponent as AddIcon } from "../assets/icon_add.svg";
import { useTranslation } from 'react-i18next';
import Sidebar from './Sidebar_Mobile';
import { AppContext } from '../contexts/AppContext';
import SearchBar from './SearchBar';
import Button from './Button';

interface HeaderProps {
  title?: string,
  showSearchBar?: boolean,
  showAddButton?: boolean,
  addButtonAction?: () => void,
  searchPhrase?: string,
  setSearchPhrase?: any
}

const HeaderElement = styled.header`
  background-color: #493D66;
  width: 100%;
  min-height: 52px;
  position: relative;
  z-index: 20;
  padding: 12px 25px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: inset 0px -0.5px 0px #CCCCCC;

    & svg path {
      fill: #fff;
    }
  }
`;

const DesktopToolbar = styled.div`
  clear: both;
`;

const Title = styled.h1`
  color: #fff;
  font-size: 1.1em;
  font-weight: bold;
  margin: 5px 0;
  padding: 0 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DesktopTitle = styled.h1`
  max-width: 50%;
  display: inline-block;
  font-size: 1.8em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 20px;

  &.full {
    max-width: 100%;
  }
`;

const TopButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 19px;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 5px;

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
  }
`

const DesktopAddButton = styled(Button)`
  margin: 5px 0;
  float: right;
`;

/**
 * Render the app header
 * @returns {JSX.Element} Component template
 */
const Header: FC<HeaderProps> = ({title, showSearchBar, showAddButton, addButtonAction, searchPhrase, setSearchPhrase}) => {
  const navigate = useNavigate();
  const { isDesktop } = useContext(AppContext);
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * Go back in history
   */
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  /**
   * Returns true if current page is the home page
   */
  const showBackButton = useMemo(() => {
    return (window.location?.pathname === "/") && document?.referrer !== window?.origin;
  }, []);

  return (
    <>
    {isDesktop ? (
      <DesktopToolbar>
        <DesktopTitle className={!showSearchBar ? "full" : undefined}>{title}</DesktopTitle>
        {showSearchBar && (<SearchBar env="desktop" searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase}/>)}
        {showAddButton && (<DesktopAddButton title={t("navigation.add")} icon='add' onClick={addButtonAction}/>)}
      </DesktopToolbar>
    ) : (
      <>
        <HeaderElement role="banner">
          {!showBackButton && (<TopButton className="left" aria-label={t("navigation.goBack") || ""} aria-expanded={menuOpen ? "true" : "false"} onClick={goBack}><BackIcon/></TopButton>)}
          {title && (<Title>{title}</Title>)}
          {showAddButton ? (
            <TopButton className="right" aria-label={t("navigation.add") || ""} onClick={addButtonAction}><AddIcon/></TopButton>
          ) : (
            <TopButton className="right" aria-haspopup="true" aria-expanded={menuOpen ? "true" : "false"} aria-label={t("navigation.showMenu") || ""} onClick={() => {setMenuOpen(true)}}><MenuIcon/></TopButton>
          )}
        </HeaderElement>
        {showSearchBar && (<SearchBar env="mobile" searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase}/>)}
        <Sidebar isOpen={menuOpen} close={() => {setMenuOpen(false)}}/>
      </>
    )}
    </>
  );
}

export default Header;