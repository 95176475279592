import { useEffect, useState } from 'react';
import { AppContext } from "./contexts/AppContext";
import { useMediaQuery } from 'react-responsive';
import i18n from "i18next";
import AppRoutes from "./Routes";
import { refreshToken } from './common/Authentication';
import styled from 'styled-components';
import SidebarDesktop from './components/Sidebar_Desktop';
import packageJson from '../package.json';
import useCheckBypassAuthentication from './hooks/useCheckBypassAuthentication';
import { getCookie } from './common/Cookies';

const ContentWrapper = styled.div`
  @media (min-width: 1000px) {
    position: relative;
    width: calc(100% - 290px);
    min-height: 100vh;
    float: right;
    padding: 50px;
  }
`;

/**
 * Main app
 * @returns {JSX.Element} Component template
 */
const App = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1000px)' });
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n?.resolvedLanguage || "no");
  const [bypassGroup] = useCheckBypassAuthentication();

  // Always refresh token on load - except when auth key is provided
  useEffect(() => {
    (async() => {
      const currentUrl = window.location.href;
      if (currentUrl.includes("?key=") || currentUrl.includes("/key/") || getCookie("remoteGroup")) {
        return
      }
      if (!currentUrl.includes("/oauth2/callback")) {
        const user = await refreshToken();
        user && setCurrentUser(user);
      }
    })();
  }, [setCurrentUser]);

  // Update i18n language on user update
  useEffect(() => {
    if (currentLanguage !== i18n?.resolvedLanguage) {
      i18n?.changeLanguage(currentLanguage);
    }
  }, [currentLanguage]);

  // Display app version in browser console on startup
  useEffect(() => {
    console.log(`%cKulturio Remote v${packageJson?.version}\n%cDeveloped by KulturIT AS`, `font-weight: bold`, ``);
  }, []);

  if(!bypassGroup && !currentUser) { return null }

  return (
    <AppContext.Provider value={{ currentUser, currentLanguage, setCurrentUser, setCurrentLanguage, bypassGroup, isDesktop }}>
        {isDesktop && (<SidebarDesktop/>)}
        <ContentWrapper>
          <AppRoutes/>
        </ContentWrapper>
    </AppContext.Provider>
  );
}

export default App;
