
/**
 * Store value in cookie
 * @param {string} name Name of cookie 
 * @param {string} val Value of cookie 
 */
export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 24 hours
  date.setTime(date.getTime() + (24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name+"="+value+"; SameSite=Strict; Secure; expires="+date.toUTCString()+"; path=/";
}

/**
 * Get value from cookie
 * @param {string} name Cookie name 
 * @returns {string} Cookie value
 */
export function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  
  if (parts.length === 2) {
    return parts?.pop()?.split(";").shift();
  }
}

/**
 * Delete cookie
 * @param {string} name Cookie name 
 */
export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
}