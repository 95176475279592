import { FC, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';

interface PaperProps {
  title?: any,
  children: any
}

interface PaperItemProps {
  label: string
  children: any
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 0 10px;
  padding: 0 15px;
  float: left;
  clear: both;

  @media screen and (min-width: 1400px) {
    column-count: 2;
    column-gap: 50px;
  }
}
`;

const Title = styled.h2`
  width: 100%;
  column-span: all;
  margin: 10px 0 5px;
  font-size: 1.3em;
`;

const Item = styled.div`
  padding: 10px 0;
  clear: both;
  border-bottom: 1px solid #ECEBEF;
  float: left;
  width: 100%;
`;

const Label = styled.label`
  color: #555;
  float: left;
`;

const Value = styled.span`
  float: right;
  font-weight: 500;
`;

/**
 * Render navigation tab wrapper
 * @returns {JSX.Element} Component template
 */
export const Paper: FC<PaperProps> = ({children, title}) => {
  const { isDesktop } = useContext(AppContext);

  return <Wrapper className={isDesktop ? "desktop" : undefined}>
    {title && (<Title>{title}</Title>)}
    {children}
  </Wrapper>
}

/**
 * Render navigation tabs
 * @returns {JSX.Element} Component template
 */
export const PaperItem: FC<PaperItemProps> = ({label, children}) => {
  return (
    <Item><Label>{label}</Label><Value>{children}</Value></Item>
  );
}