import { PCS_API_KEY, PCS_API_URL, SEND_AUTH_TOKEN_ON_REQUESTS } from '../Settings';

/**
 * API mockup based on a limited set of test data, available in testdata.json
 */
abstract class Api {

  /**
   * 
   * @param url Fetch URL
   * @returns 
   */
  static async fetchData(options: { method: string, url: string, body?: any, user?: string, headers?: any[any], noheaders?: boolean} ) {
    let headers: any = options?.headers || {};

    headers = {...headers, ...{"x-api-key": PCS_API_KEY}}
    if (SEND_AUTH_TOKEN_ON_REQUESTS){
      const token = localStorage.getItem("kit.access_token");
      if (token) headers = {...headers, ...{"Authorization": `Bearer ${token}`}}
    }

    if (options?.noheaders) { headers = undefined }
    if (options?.user) {
      //headers = {...headers, ...{"user-id": options?.user}}
    }
    return fetch(options?.url, {
      method: options?.method,
      headers: headers,
      body: (options?.method !== "GET") ? (JSON.stringify(options?.body) || null) : null
    }).then(response => {
      if (response?.status !== 200) {
        return Promise.reject({message: "error.fetchError", error: response?.status});
      }
      return response?.json()
    }
    ).then(data => {
      return data;
    }).catch(e => {
      if (!navigator.onLine) {
        return Promise.reject({message: "error.noNetwork", error: e});
      } else {
        return Promise.reject({message: "error.fetchError", error: e});
      }
    });
  }

  /**
   * Get client
   * @param query.id {string} Client ID
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async getClient(query: { id: string, user: string }) {
    if (query?.id === "undefined" || typeof query?.id === "undefined") { return null; }
    const url = `${PCS_API_URL}clients/${query?.id}`;

    return this.fetchData({ method: "GET", url: url, user: query?.user });
  }

  /**
   * Delete client
   * @param query.id {string} Client ID
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
    public static async deleteClient(query: { id: string, user: string }) {
      if (query?.id === "undefined" || typeof query?.id === "undefined") { return null; }
      const url = `${PCS_API_URL}clients/${query?.id}`;

      return this.fetchData({ method: "DELETE", url: url, user: query?.user });
    }

  /**
   * Get config
   * @param query.id {string} Config ID
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async getConfig(query: { id: string, user: string }) {
    if (query?.id === "undefined" || typeof query?.id === "undefined") { return null; }
    const url = `${PCS_API_URL}config/${query?.id}`;
    
    return this.fetchData({ method: "GET", url: url, user: query?.user });
  }

  /**
   * Delete config
   * @param query.id {string} Config ID
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async deleteConfig(query: { id: string, user: string }) {
    if (query?.id === "undefined" || typeof query?.id === "undefined") { return null; }
    const url = `${PCS_API_URL}config/${query?.id}`;

    return this.fetchData({ method: "DELETE", url: url, user: query?.user });
  }

  /**
   * Update config
   * @param query.id {string} Config ID
   * @param query.body {object} Configuration
   * @param query.user {string} User ID
   * @returns {object} Full document
   */
  public static async updateConfig(query: { id: string, body: any, user: string }) {
    if (query?.id === "undefined" || typeof query?.id === "undefined") { return null; }
    const url = `${PCS_API_URL}config`;

    return this.fetchData({ method: "POST", url: url, body: query?.body, user: query?.user, headers: {"client-id": query.id} });
  }

  /**
   * Send task to client
   * @param query.clientId {string} Client ID
   * @param query.tasks {object} Tasks to be run
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async sendTasksToClient(query: { clientId: string, tasks: {"type": string, "function": string}[], user: string }) {
    if (query?.clientId === "undefined" || typeof query?.tasks === "undefined") { return null; }
    const url = `${PCS_API_URL}tasks`;

    return this.fetchData({ method: "POST", url: url, body: { clientId: query?.clientId, tasks: query?.tasks }, user: query?.user });
  }

  /**
   * Setup client
   * @param query.sourceId {string} Source config ID
   * @param query.destId {string} Destination client ID
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async setupClient(query: { sourceId: string, destId?: any, user: string }) {
    if (typeof query?.sourceId === "undefined") { return null; }
    const url = `${PCS_API_URL}setupClient`;

    return this.fetchData({ method: "POST", url: url, body: { clientId: query?.sourceId, destClientId: query?.destId }, user: query?.user });
  }

  /**
   * Get client list
   * @param query.options {object} Options
  * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async getClientList(query: { options: any, user: string }) {
    const params = new URLSearchParams();
    query?.options?.rows && params.set('rows', query?.options.rows);
    const url = `${PCS_API_URL}clients/?${params.toString()}`;

    return this.fetchData({ method: "GET", url: url, user: query?.user });
  }

  /**
   * Get config list
   * @param query.options {object} Options
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async getConfigList(query: { options: any, user: string }) {
    const params = new URLSearchParams();
    query?.options?.rows && params.set('rows', query?.options.rows);
    const url = `${PCS_API_URL}config/?${params.toString()}`;

    return this.fetchData({ method: "GET", url: url, user: query?.user });
  }

  /**
   * Collect statistics
   * @param query.options {object} Options
   * @param query.user {string} Current user ID
   * @returns {object} Full document
   */
  public static async getStatistics(query: { clientId: string, startDate: any, endDate?: any, user: string }) {
    const url = `${PCS_API_URL}statistics/${query?.clientId}/${new Date(query?.startDate)?.getTime()}/${new Date(query?.endDate)?.getTime()}`;

    return this.fetchData({ method: "GET", url: url, noheaders: !SEND_AUTH_TOKEN_ON_REQUESTS }); // , user: query?.user
  }
}

export default Api;
