import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import noCommon from "./no/common.json";
import svCommon from "./sv/common.json";
import enCommon from "./en/common.json";

/**
 * I18n language configuration settings
 */
export const resources = {
  no: { common: noCommon },
  sv: { common: svCommon },
  en: { common: enCommon }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: {
      'default': ['no']
    },
    ns: ["common", "db"],
    defaultNS: "common",
    lowerCaseLng: true,
    cleanCode: true,
    resources,
  });

export default i18n;
